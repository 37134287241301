'use client';

import { useMemo } from 'react';
import { Button, NumberInput, Stack, Group, Text } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { IconTrash } from '@tabler/icons-react';
import { format } from 'date-fns';
import { Debt, DebtUpdate, NextDebtUpdate } from '@/types';
import {
  formatCurrency,
  formatDateToISO,
  parseISOToDate,
  setNullToUndefined,
  getInterestCharged,
} from '@/utils';

interface DebtUpdateFormProps {
  debt?: Debt;
  initialValues?: NextDebtUpdate;
  onSubmit: (debtUpdate: Omit<DebtUpdate, 'id'>) => void;
  onCancel: () => void;
  onDelete?: () => void;
  submitLabel?: string;
  isLoading?: boolean;
}

export function DebtUpdateForm({
  debt,
  initialValues = {},
  onSubmit,
  onCancel,
  onDelete,
  submitLabel = 'Submit',
  isLoading,
}: DebtUpdateFormProps) {
  const initialFormValues = {
    ...initialValues,
    startDate: parseISOToDate(initialValues.startDate),
    endDate: parseISOToDate(initialValues.endDate),
    balance: setNullToUndefined(initialValues.balance),
    minimumPayment: setNullToUndefined(initialValues.minimumPayment),
    interestCharged: setNullToUndefined(initialValues.interestCharged),
    payment: setNullToUndefined(initialValues.payment),
  };

  const form = useForm({
    initialValues: initialFormValues,
    validate: {
      startDate: (value) => !value && 'Start date is required',
      endDate: (value) => !value && 'End date is required',
      balance: (value) => !value && 'Balance is required',
      minimumPayment: (value) => !value && 'Minimum payment is required',
      interestCharged: (value) => !value && 'Interest charged is required',
    },
  });

  const estimatedInterestCharged = useMemo(() => {
    return getInterestCharged(Number(form.values.balance), debt?.apr);
  }, [form.values.balance, debt?.apr]);

  const onUpdateInterestCharged = (e: React.MouseEvent) => {
    e.preventDefault();
    if (estimatedInterestCharged !== null) {
      form.setFieldValue('interestCharged', estimatedInterestCharged);
    }
  };

  const balanceAfterPayment = useMemo(() => {
    if (!form.values.balance || !form.values.payment) return null;
    const value = Number(form.values.balance) - Number(form.values.payment);
    return Math.max(value, 0);
  }, [form.values.balance, form.values.payment]);

  const handleSubmit = form.onSubmit((values) => {
    const submissionValues = {
      ...values,
      startDate: formatDateToISO(values.startDate, false),
      endDate: formatDateToISO(values.endDate, false),
      balance: values.balance === '' ? null : values.balance,
      minimumPayment:
        values.minimumPayment === '' ? null : values.minimumPayment,
      interestCharged:
        values.interestCharged === '' ? null : values.interestCharged,
      payment: values.payment === '' ? null : values.payment,
    };
    onSubmit(submissionValues as Omit<DebtUpdate, 'id'>);
  });

  return (
    <form onSubmit={handleSubmit}>
      <Stack>
        <Stack gap={2}>
          <Text size="sm" fw={500}>
            Billing period
          </Text>
          <Group gap="xs">
            <DateInput
              placeholder="Select start date"
              weekendDays={[]}
              popoverProps={{ zIndex: 1000 }}
              valueFormat="MMM D, YYYY"
              flex={1}
              inputMode="none"
              autoComplete="off"
              {...form.getInputProps('startDate')}
            />
            <Text>–</Text>
            <DateInput
              placeholder="Select end date"
              minDate={form.getInputProps('startDate').value}
              weekendDays={[]}
              popoverProps={{ zIndex: 1000 }}
              valueFormat="MMM D, YYYY"
              flex={1}
              inputMode="none"
              autoComplete="off"
              {...form.getInputProps('endDate')}
            />
          </Group>
          {form.values.endDate ? (
            <Text size="xs" c="dimmed" mt={4}>
              Due date for this billing period:{' '}
              {format(form.values.endDate, 'MMM d, yyyy')}
            </Text>
          ) : null}
        </Stack>

        <NumberInput
          label="Balance before payment"
          placeholder="Enter balance before payment"
          prefix="$"
          min={0}
          max={100000000}
          decimalScale={2}
          thousandSeparator=","
          inputMode="decimal"
          autoComplete="off"
          {...form.getInputProps('balance')}
        />

        <NumberInput
          label="Minimum payment"
          placeholder="Enter minimum payment"
          prefix="$"
          min={0}
          max={form.getInputProps('balance').value || 100000000}
          decimalScale={2}
          thousandSeparator=","
          inputMode="decimal"
          autoComplete="off"
          {...form.getInputProps('minimumPayment')}
        />

        <Stack gap={6}>
          <NumberInput
            label="Interest charged"
            placeholder="Enter interest charged"
            prefix="$"
            min={0}
            max={form.getInputProps('balance').value || 100000000}
            decimalScale={2}
            thousandSeparator=","
            inputMode="decimal"
            autoComplete="off"
            {...form.getInputProps('interestCharged')}
          />
          {typeof form.values.interestCharged === 'number' &&
            estimatedInterestCharged !== null &&
            formatCurrency(estimatedInterestCharged) !==
              formatCurrency(form.values.interestCharged) && (
              <Group gap={6}>
                <Text size="xs" c="dimmed">
                  Based on {debt?.apr}% APR, estimated interest charged is{' '}
                  {formatCurrency(estimatedInterestCharged)}.
                </Text>
                <button onClick={onUpdateInterestCharged} disabled={isLoading}>
                  <Text size="xs" c="blue" fw={500}>
                    Use this
                  </Text>
                </button>
              </Group>
            )}
        </Stack>

        <Stack gap={6}>
          <NumberInput
            label="Your actual payment"
            placeholder="Enter payment"
            prefix="$"
            min={0}
            max={form.getInputProps('balance').value || 100000000}
            decimalScale={2}
            thousandSeparator=","
            inputMode="decimal"
            autoComplete="off"
            {...form.getInputProps('payment')}
          />
          <Text size="xs" c="dimmed">
            Balance after payment:{' '}
            {balanceAfterPayment !== null
              ? formatCurrency(balanceAfterPayment)
              : '--'}
          </Text>
        </Stack>

        <Group
          justify={onDelete ? 'space-between' : 'flex-end'}
          align="center"
          mt="md"
          w="100%"
        >
          {onDelete && (
            <Button
              variant="subtle"
              onClick={onDelete}
              py={4}
              px="xs"
              color="red.4"
            >
              <IconTrash size={24} />
            </Button>
          )}

          <Group justify="flex-end">
            <Button variant="light" onClick={onCancel} disabled={isLoading}>
              Cancel
            </Button>
            <Button type="submit" loading={isLoading}>
              {submitLabel}
            </Button>
          </Group>
        </Group>
      </Stack>
    </form>
  );
}
